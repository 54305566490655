import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AutomobileService {

  constructor(
    private http: HttpClient
  ) { }

  // search assuré
  searchAssureByName(token: string, searchParam: string, procedureName: string) {
    return this.http.get(`${environment.assureBaseUrl}/api/Request`, {params: {
      token, 
      jsonParametre: searchParam, 
      nomProcedure: procedureName,
      identifiant: `${environment.identProcess}`
    }});
  }

  // List garanties with caracteristics
  getAllGaranties(code, token) {
    return this.http.get(`${environment.automobileBaseUrl}/api/v1/Tarification/categoriegarantiecaracteristique?codecate=${code}`, {params: {token}});
  }

  // Get referential data
  getAllDatas(token) {
    return this.http.get(`${environment.subscriptionBaseUrl}/api/v1/Souscription/ReferentialData`, {params: {token}});
  }

  // Get referential datas per category
  getAllDatasPerCategory(code, token) {
    return this.http.get(`${environment.subscriptionBaseUrl}/api/v1/Souscription/ReferentialDataByCategorie/${code}`, {params: {token}});
  }

  // Tarification per garantie
  tarificationGarantie(data: any, token) {
    return this.http.post(`${environment.automobileBaseUrl}/api/v1/Tarification/tarifgarantie`, data, {params: {token}});
  }

  // Tarification per contract
  tarificationContrat(data: any, token) {
    return this.http.post(`${environment.automobileBaseUrl}/api/v1/Tarification/tarifpolice`, data, {params: {token}});
  }

  // Tarification for flotte
  tarificationFlotte(data: any, token) {
    return this.http.post(`${environment.automobileBaseUrl}/api/v1/Tarification/tarifflotte`, data, {params: {token}});
  }

  // Commission per garantie
  commissionGarantie(data: any, token) {
    return this.http.post(`${environment.automobileBaseUrl}/api/v1/Tarification/commissiongarantie`, data, {params: {token}});
  }

  // Commission per contract
  commissionContrat(data: any, token) {
    return this.http.post(`${environment.automobileBaseUrl}/api/v1/Tarification/commissionpolice`, data, {params: {token}});
  }

  // Commission for flotte
  commissionFlotte(data: any, token) {
    return this.http.post(`${environment.automobileBaseUrl}/api/v1/Tarification/commissionflotte`, data, {params: {token}});
  }
  
  // Subscription contract
  generateBearerToken() {
    return this.http.post(`${environment.subscriptionBaseUrl}/api/v1/Authentication/Authenticate`, {
      "userId": `${environment.autoAuthApiUser}`,
      "password": `${environment.autoAuthApiPassword}`
    });
  }

  // Subscription contract
  generateUserBearerToken() {
    return this.http.post(`${environment.assureBaseUrl}/api/Authentication/Authenticate`, {
      "nomutil": `${environment.assureAuthApiUser}`,
      "userkey": `${environment.assureAuthApiPassword}`
    });
  }

  // Subscription contract
  generateAutoBearerToken() {
    return this.http.post(`${environment.subscriptionBaseUrl}/api/v1/Authentication/Authenticate`, {
      "userId": `${environment.autoAuthApiUser}`,
      "password": `${environment.autoAuthApiPassword}`
    });
  }

  // Subscription contract
  souscriptionPolice(data: any, token) {
    return this.http.post(`${environment.subscriptionBaseUrl}/api/v1/Souscription/NewContractCIV`, data, {params: {token}});
  }

  // Save encaissement
  saveEncaissement(data: any, token) {
    return this.http.post(`${environment.subscriptionBaseUrl}/api/v1/Encaissement/Encaissement`, data, {params: {token}});
  }

  savePolice(data: any/*, token*/) {
    return this.http.post(`${environment.automobileExtraBaseUrl}/policy/save-policy`, data, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }

  printCarteBrune(data: any, token) {
    return this.http.post(`${environment.printingBaseUrl}/api/Report/GetPdfCarteBruneReport`, data, {params: {token}});
  }

  printCP(data: any, token) {
    return this.http.post(`${environment.printingBaseUrl}/api/Report/GetPdfCPReport`, data, {params: {token}});
  }

  printReport(data: any, type, token) {
    return this.http.post(`${environment.printingBaseUrl}/api/Report/GetPdfReport/${type}`, data, {params: {token}});
  }

  printAttestation(idctr: string, data:any, token) {
    return this.http.post(`${environment.subscriptionBaseUrl}/api/v1/Souscription/EditerAttestation_v2`, data, {params: {idctr, token}});
  }

  getSavedContrat(numPolice: string/*, token*/) {
    return this.http.get(`${environment.automobileExtraBaseUrl}/policy/get/${numPolice}`, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }

  getNumeroPolice(/*token*/) {
    return this.http.get(`${environment.automobileExtraBaseUrl}/policy/generate`, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }

  getAttestationNumbers(posId/*token*/) {
    return this.http.get(`${environment.automobileExtraBaseUrl}/attestations/available/${posId}`, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }

  checkPrintPremission(numPolice: string, data/*token*/) {
    return this.http.post(`${environment.automobileExtraBaseUrl}/attestations/check-print/${numPolice}`, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }

  sendPrintStatus(data: any/*token*/) {
    return this.http.post(`${environment.automobileExtraBaseUrl}/attestations/save-policy`, data, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }

  sendContractSubmitConfirm(numPolice: string/*token*/) {
    return this.http.get(`${environment.automobileExtraBaseUrl}/attestations/check-print/${numPolice}`, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }

  updateContractWithCardNumber(idctr: string, data: any, token) {
    return this.http.put(`${environment.subscriptionBaseUrl}/api/v1/Souscription/SetNumeroCarte/${idctr}`, data, {params: {token}});
  }

  getConditionFields(codecate/*token*/) {
    return this.http.get(`${environment.automobileExtraBaseUrl}/produit/listparmasreglesouscription/${codecate}`, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }

  checkParticularConditions(codecate: string, data/*token*/) {
    return this.http.post(`${environment.automobileExtraBaseUrl}/produit/checkRules/${codecate}`, data, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }


  //renewals
  renouvellementPolice(data: any, token) {
    return this.http.post(`${environment.subscriptionBaseUrl}/api/v1/Renouvellement/RenewContractCIV`, data, {params: {token}});
  }

  //contract differential
  getDifferentielPolice(data: any, token) {
    return this.http.post(`${environment.subscriptionBaseUrl}/api/v1/Souscription/DifferrentialContract`, data, {params: {token}});
  }

  // get types avenants
  getTypesAvenants(token: string) {
    return this.http.get(`${environment.assureBaseUrl}/api/Request`, {params: {
      token, 
      jsonParametre: "{}", 
      nomProcedure: "GetTypeAvenant",
      identifiant: `${environment.identProcess}`
    }});
  }

  //get garanties and dependances
  getProductGarantiesConditions(codecate: string) {
    return this.http.get(`${environment.automobileExtraBaseUrl}/produit/getallgarantiedeps/${codecate}`, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }

  // recherche historique validations
  getValidationsHistory(data, token) {
    return this.http.get(`${environment.subscriptionBaseUrl}/api/v1/Souscription/GetBordereauProd/${data.codeunite}/${data.date_debut}/${data.date_fin}?codecate=${data.categorie}`, {params: {token}});
  }

  // recherche historique validations
  getPropositionsHistory(data) {
    return this.http.get(`${environment.automobileExtraBaseUrl}/policy/list-proposition`, {
      params: {
        token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN'),
        dateDebut: data.date_debut,
        dateFin: data.date_fin,
        categorie: data.categorie
      }
    });
  }

  // search polices for avenant
  searchPolicies(data) {
    return this.http.get(`${environment.automobileExtraBaseUrl}/policy/search-policies`, {
      params: {
        token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN'),
        numeroPolice: data.num_police,
        nomSouscripteur: data.nom_souscript,
        immatriculation: data.immatriculation
      }
    });
  }

  // search polices from contrat for avenant
  searchPoliciesFromContrat(data: any, token) {
    return this.http.post(`${environment.subscriptionBaseUrl}/api/v1/Souscription/RechercheContratCIV_v2`, null, {
      params: {
        token: token,
        numepolice: data.num_police,
        nomclient: data.nom_souscript,
        numimma: data.immatriculation
      }
    });
  }

  
  // synchronise contract
  syncPolice(data: any/*, token*/) {
    return this.http.post(`${environment.automobileExtraBaseUrl}/policy/sync-policy`, data, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }

  //update police from MS contrat to showroom
  updatePolice(data: any) {
    return this.http.post(`${environment.automobileExtraBaseUrl}/avenant/save-avenant`, data, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }

  // search saved avenants
  searchSavedAvenants(data) {
    return this.http.get(`${environment.automobileExtraBaseUrl}/avenant/search-avenants`, {
      params: {
        token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN'),
        numeroPolice: data.num_police,
        nomSouscripteur: data.nom_souscript,
        immatriculation: data.immatriculation
      }
    });
  }

  // retrieve saved avenant
  getSavedAvenant(numAvenant: string) {
    return this.http.get(`${environment.automobileExtraBaseUrl}/avenant/get/${numAvenant}`, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }

  //save avenant
  saveAvenant(data: any) {
    return this.http.post(`${environment.automobileExtraBaseUrl}/avenant/save-avenant`, data, {params: {token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN')}});
  }
  

  // search for latest pending avenant on contract
  getPendingAvenants(data) {
    return this.http.get(`${environment.automobileExtraBaseUrl}/avenant/get-latest-avenant`, {
      params: {
        token: localStorage.getItem('ACTIVA_REDACTEUR_TOKEN'),
        policynumber: data.policynumber
      }
    });
  }
}